
import React from 'react'
import styled from 'styled-components'
import { Accordion } from 'react-accessible-accordion'
import 'react-accessible-accordion/dist/fancy-example.css'

import { localStorage } from 'components/polyfills/local-storage'
import { Button } from 'components/form/button'
import { GeneratorSection } from 'components/generator/section'

const StyledWrapper = styled.div`
    max-width: 80%;
    margin: 0 auto;
    padding-top: 25px;
`

export default class Generator extends React.Component {

    constructor(props) {
        super(props)
        this.state = {}

        if (props.pageContext.generatorObject) {

            this.generatorObject = props.pageContext.generatorObject

        } else if (props.location.pathname.includes('/local/')) {

            let [, localGeneratorName] = this.props.location.pathname.split('/local/')
            this.generatorObject = JSON.parse(localStorage.getItem(`${process.env.GENERATOR_PREFIX_LOCAL}${localGeneratorName}`))

        }
    }

    render() {
        return <StyledWrapper>
            <Button type='submit'>Generate</Button>
            <Accordion>
                <GeneratorSection {...this.generatorObject} />
            </Accordion>
        </StyledWrapper>
    }

}